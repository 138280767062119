import React from "react";
import { Row, Col } from "react-bootstrap";
import Img, { FluidObject } from "gatsby-image";
import { useMediaQuery } from "react-responsive";

import styles from "./ukuran.module.scss";

interface SlideTwoProps {
  prevSlide: () => void;
  tabelUkuran: FluidObject;
  guideUkuran: FluidObject;
  arrowUp: string;
}

export default function SlideTwo({
  prevSlide,
  tabelUkuran,
  guideUkuran,
  arrowUp,
}: SlideTwoProps) {
  const isDesktop = useMediaQuery({ minWidth: 992 });

  return (
    <div className={styles.ukuran}>
      <Row className={styles.title}>
        <h1>
          Standar Ukuran{isDesktop ? ` ` : <br />}
          <span style={{ color: "#db9a09" }}>Seragam Kami</span>
        </h1>
      </Row>
      <Row className={styles.content}>
        <Col md={6} className={styles.guide}>
          <Img
            fluid={guideUkuran}
            className="w-100 h-100"
            imgStyle={{
              width: "100%",
              height: "100%",
              objectFit: "contain",
            }}
          />
        </Col>
        <Col md={6} className={styles.table}>
          <Img
            fluid={tabelUkuran}
            className="w-100 h-100"
            imgStyle={{
              width: "100%",
              height: "100%",
              objectFit: "contain",
            }}
          />
        </Col>
      </Row>
      <Row className={styles.arrowContainer}>
        <div className={styles.nextSlide} onClick={prevSlide}>
          <img src={arrowUp} alt="arrow up" />
          <span className={styles.back}>Kembali Lihat Video Tutorial Cara Mengukur</span>
        </div>
      </Row>
    </div>
  );
}
