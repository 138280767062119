import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import Video from "@components/Video";
import { measurementVideos } from "@utils/static-data";

import styles from "./ukuran.module.scss";

interface SlideOneProps {
  nextSlide: () => void;
  arrowDown: string;
}

export default function SlideOne({ nextSlide, arrowDown }: SlideOneProps) {
  const [activeTabs, setActiveTabs] = useState<number>(0);
  const isDesktop = useMediaQuery({ minWidth: 992 });

  const renderMeasurementDescList = () => {
    return measurementVideos.map((data, idx) => (
      <div
        key={idx}
        className={`${styles.listItem} ${
          idx === activeTabs ? styles.active : ``
        }`}
        onClick={() => setActiveTabs(idx)}
      >
        <span>{idx + 1}. &nbsp;</span>
        <span dangerouslySetInnerHTML={{ __html: data.title }} />
      </div>
    ));
  };

  const renderMeasurementVideo = () => {
    let title = measurementVideos[activeTabs].title;
    let link = measurementVideos[activeTabs].link;

    return (
      <>
        <p dangerouslySetInnerHTML={{ __html: title }} />
        <div className={styles.videoContainer}>
          <Video link={link} quality="sd" imgStyle={{ borderRadius: 0 }} />
        </div>
      </>
    );
  };

  return (
    <div className={styles.ukuran}>
      <Row className={styles.title}>
        <h1>
          Video Tutorial{isDesktop ? ` ` : <br />}
          <span style={{ color: "#db9a09" }}>Cara Mengukur</span>
        </h1>
      </Row>
      <Row className={styles.content}>
        <Col md={6} className={styles.video}>
          {renderMeasurementVideo()}
        </Col>
        <Col md={6} className={styles.list}>
          {renderMeasurementDescList()}
        </Col>
      </Row>
      <Row className={styles.arrowContainer}>
        <div className={styles.nextSlide} onClick={nextSlide}>
          <img src={arrowDown} alt="arrow down" />
          <span>Lihat Standar Ukuran Kami</span>
        </div>
      </Row>
    </div>
  );
}
