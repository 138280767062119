import React, { useRef } from "react";
import BgImage from "gatsby-background-image";
import Slider from "react-slick";
import Layout from "@components/Layout";
import SlideOne from "./SlideOne";
import SlideTwo from "./SlideTwo";
import { StandarUkuranProps } from "src/types";

import styles from "@views/StandarUkuran/ukuran.module.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function StandarUkuran(props: StandarUkuranProps) {
  const sliderRef = useRef<Slider | null>(null);
  const {
    bgDesktop,
    bgMobile,
    guideUkuran,
    tabelUkuran,
    arrowUp,
    arrowDown,
  } = props.data;

  const nextSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  const prevSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  // Art directing image
  const bg = [
    bgMobile.childImageSharp.fluid,
    {
      ...bgDesktop.childImageSharp.fluid,
      media: "(min-width: 992px)",
    },
  ];

  const sliderSettings = {
    dots: false,
    infinite: false,
    vertical: true,
    arrows: false,
    swipe: false,
    verticalSwiping: false,
    swipeToSlide: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    className: styles.heroSlider,
  };

  return (
    <Layout noFooter>
      <BgImage fluid={bg} alt="background">
        <Slider {...sliderSettings} ref={sliderRef}>
          <SlideOne nextSlide={nextSlide} arrowDown={arrowDown.publicURL} />
          <SlideTwo
            prevSlide={prevSlide}
            tabelUkuran={tabelUkuran.childImageSharp.fluid}
            guideUkuran={guideUkuran.childImageSharp.fluid}
            arrowUp={arrowUp.publicURL}
          />
        </Slider>
      </BgImage>
    </Layout>
  );
}
