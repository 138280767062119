import React from "react";
import { graphql } from "gatsby";
import StandarUkuran from "@views/StandarUkuran";
import { StandarUkuranProps } from "src/types";

export const pageQuery = graphql`
  query UkuranQuery {
    bgDesktop: file(relativePath: { eq: "bg/desktop/bg-polos.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000, maxHeight: 500, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bgMobile: file(relativePath: { eq: "bg/mobile/bg-polos.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 625, maxHeight: 1104, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    guideUkuran: file(relativePath: { eq: "guide-ukuran.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1091, maxHeight: 1153, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    tabelUkuran: file(relativePath: { eq: "table-ukuran-desktop.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 863, maxHeight: 583, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    arrowUp: file(relativePath: { eq: "icon/icon-arrow-up.svg" }) {
      publicURL
    }
    arrowDown: file(relativePath: { eq: "icon/icon-arrow-down.svg" }) {
      publicURL
    }
  }
`;

export default function StandarUkuranRoot(props: StandarUkuranProps) {
  return <StandarUkuran {...props} />;
}
